import React from "react";
import "../Pages/Main/Main.css";
import { ServeIons1, ServeIons2, ServeIons3 } from "../Data";

const We_Serve = () => {
  return (
    <>
      <div className="grid_server_container1 grid">
        {ServeIons1.map(({ icon, name }, index) => {
          return (
            <div className="icon_name_serve_container1" key={index}>
              <div className="icon_name_serve_container2">
                <span className="icon_serve">{icon}</span>
                <div className="heading_serve">{name}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid_server_container2 grid">
        {ServeIons2.map(({ icon, name }, id) => {
          return (
            <div className="icon_name_serve_container1" key={id}>
              <div className="icon_name_serve_container2">
                <span className="icon_serve">{icon}</span>
                <div className="heading_serve">{name}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="grid_server_container3 grid">
        {ServeIons3.map(({ icon, name }, ids) => {
          return (
            <div className="icon_name_serve_container1" key={ids}>
              <div className="icon_name_serve_container2">
                <span className="icon_serve">{icon}</span>
                <div className="heading_serve">{name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default We_Serve;
