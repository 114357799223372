import React from "react";
import "./AreYou.css";
import Footer from "../../Components/notation";
import { FaQuoteLeft } from "react-icons/fa";
import { areyou } from "../../Data";


const AreYou = () => {
  return (
    <>
      <main className="areyou_content_main">
        <section className="section_first_Areyou linear_grdient_section">
          <div className="areyou_img mix_blend_img"></div>
          <div className="are_you_section_first_container1 padding_container_text_container1">
            <div className="contents-headings">
              <h1 className="areyou_content_heading title services_parts_heading">
                Are You a Startup?
              </h1>
            </div>
          </div>
        </section>
        <section className="section_areyou_second">
          <div className="gridbox_container_content_areyou grid">
            <div className="areyou_content1">
              <span>
                <FaQuoteLeft className="coma" />
              </span>
              <p className="para bigpara_areyou">
                We are committed to help you survive and thrive in this fiercely
                competitive economy!
              </p>
            </div>
            <div className="areyou_content2">
              <p className="para small_para_areyou">
                As an entrepreneur, you are often faced with huge decisions when
                your company is in its very early stages: A skeleton crew of
                team members, minimal sales, and less funds. While your focus is
                understandably on immediate survival, and how you can hit your
                first big hurdles, what else should you be thinking of,
                especially where your finances are concerned? What do you do
                with your startup funds?
              </p>
            </div>
          </div>
        </section>
        <section className="section_areyou_third">
          <div className="para_container_section3">
            <p className="para para_third_section_1">
              At APVG & Co we assist our clients manage their funds, and align
              their strategy with the investments raised. This is critical
              because many of you entrepreneurs use your life’s savings to make
              the leap into independence.
            </p>
          </div>
        </section>
        <section className="section_fourth_areyou">
          <div className="gridbox_areyou_content_section_fourth">
            <div className="grid grid_containers_areyou">
              <div className="grid_box1_content">
                <h3 className="weprovide_heading subtitle">We Provide</h3>
              </div>
              <div className="grid_box2_content"></div>
              <div className="grid_box3_content grid grid_icons_heading_areyou">
                {areyou.map(({ icon, name }, index) => {
                  return (
                    <div key={index} className="conatiner_areyou_items">
                      <div className="icons_short_span">
                        <span className="icons_shorts">{icon}</span>
                      </div>
                      <p className="para subtitle areyou_shorts_heading">
                        {name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_areyou_third">
          <div className="para_container_section3 enquire_get">
            <div className="flexBox_get flex">
              <div>
                <h2 className="getintouch">Get In Touch With Us Today</h2>
              </div>
              <div>
                <button className="contact_button enquire">
                  <span className="link_button">
                    <a link="/contact">
                      Enquire Now <AiOutlineArrowRight />
                    </a>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section> */}
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default AreYou;
