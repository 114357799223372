import React from "react";
import { FaInstagram, FaTwitter, FaFacebookF } from "react-icons/fa";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaGlobe,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const footer = () => {
  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_items_containers grid">
          <ul className="footer_item_container_points">
            <h2 className="footer_heading_apvg_logo">
              APVG<span> & CO.</span>
            </h2>
            <p className="para icon_content_para_footer">
              We enable business transformation with cutting-edge technologies.
            </p>
          </ul>
          <ul className="footer_item_container_points">
            <li className="icons_address_other flex">
              <span className="icon_footer">
                <FaMapMarkerAlt />
              </span>
              <p className="para icon_content flex">
                <Link
                  to="https://goo.gl/maps/LK5N2Pne3WvrzFQZ7"
                  target="_blank_"
                >
                  Unit No.601, 6th Floor, Aggarwal Cyber Plaza- 1, Netaji
                  <br></br>
                  Subhash Place, Pitampura , Delhi – 110034
                </Link>
              </p>
            </li>

            <li className="icons_address_other flex">
              <span className="icon_footer">
                <FaPhoneAlt />
              </span>
              <p className="para icon_content flex">+91-9711926119</p>
            </li>
            <li className="icons_address_other flex">
              <span className="icon_footer">
                <FaEnvelope />
              </span>
              <p className="para icon_content flex">
                <Link to="https://mail.google.com/mail/u/0/" target="_blank_">
                  Admin@apvg.co.in
                </Link>
              </p>
            </li>
            <li className="icons_address_other flex">
              <span className="icon_footer">
                <FaGlobe />
              </span>
              <p className="para icon_content flex">
                <Link to="http://apvg.co.in/" target="_blank_">
                  www.apvg.co.in
                </Link>
              </p>
            </li>
          </ul>
          <ul className="footer_item_container_points">
            <h3 className="links_useful_heading">USEFULL LINKS</h3>
            <li className="icons_address_other use_links flex">
              <p className="para icon_content icon_content_link flex">
                <Link
                  to="https://www.incometax.gov.in/iec/foportal"
                  target="_blank_"
                >
                  Income Tax
                </Link>
              </p>
            </li>
            <li className="icons_address_other use_links flex">
              <p className="para icon_content icon_content_link flex">
                <Link to="https://www.gst.gov.in/" target="_blank_">
                  GST
                </Link>
              </p>
            </li>
            <li className="icons_address_other use_links flex">
              <p className="para icon_content icon_content_link flex">
                <Link
                  to="https://www.mca.gov.in/content/mca/global/en/home.html"
                  target="_blank_"
                >
                  MCA
                </Link>
              </p>
            </li>
            <ul className="soical_icons_list flex">
              <li className="social_icon ">
                <Link to="#">
                  <FaInstagram />
                </Link>
              </li>
              <li className="social_icon ">
                <Link to="#">
                  <FaTwitter />
                </Link>
              </li>
              <li className="social_icon ">
                <Link to="#">
                  <FaFacebookF />
                </Link>
              </li>
            </ul>
          </ul>
        </div>
        <p className="para copywrite_head">
          Copyright © 2022 - 2023 APVG&CO. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default footer;
