import React from "react";
import Footer from "../../Components/notation";

const India = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="India_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                India Specific Services
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_first_child"></div>
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first_dropdown1">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Corporate Social Responsibility Audit
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          A CSR audit is an evaluation of an organization’s
                          progress toward implementing socially responsible and
                          responsive programs. The process of evaluating a
                          firm’s various operating code of conduct, procedures
                          other factors to determine its effect on a society. A
                          review of a company’s social responsibility is an
                          audit that looks at factors such as a company’s record
                          of charitable giving, volunteer activity, energy use,
                          transparency, work environment and worker pay and
                          benefits to evaluate what kind of social and
                          environmental impact a company is having in the
                          locations where it operates.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Transfer Pricing
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Transfer Pricing law was introduced in India in 2001.
                          It deals with curbing tax avoidance by laying down
                          norms for computation of income arising from
                          international transactions or specified domestic
                          transactions (“SDTs”) having regard to the “arm’s
                          length price”. Our Transfer Pricing services offer
                          effective solutions to companies which undertake
                          international transactions or specified domestic
                          transactions with its associated enterprise or group
                          companies which are as follows:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Transfer Pricing Analysis</li>
                          <li>Selection of the appropriate method</li>
                          <li>Benchmarking Analysis</li>
                          <li>
                            Assistance in preparation of Transfer Pricing
                            documentation
                          </li>
                          <li>Issuance of Transfer Pricing Certificate</li>
                          <li>
                            Representing Transfer Pricing Cases before Transfer
                            Pricing Officer/ Appellate Authorities
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Activities performed under FAV/ FAR
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Achieve regulatory compliance applicable to the
                            organization
                          </li>
                          <li>
                            Identification of any ghost assets in the books of
                            accounts
                          </li>
                          <li>
                            Assistance in performing dynamic inventory
                            verification
                          </li>
                          <li>
                            Ensure accurate depreciation calculation for
                            reporting purposes
                          </li>
                          <li>
                            Assistance in movement and disposal of asset in a
                            proper manner
                          </li>
                          <li>
                            Ascertainment of variance between books and physical
                            verification
                          </li>
                          <li>
                            Bar Coding of individual asset tags will
                            significantly reduce the effort necessary for
                            verification of inventories
                          </li>
                          <li>
                            Enables multiple concurrent inventories with
                            centralized reconciliation
                          </li>
                          <li>
                            Ascertain fair value of the fixed assets that may
                            reduce insurance and tax costs
                          </li>
                          <li>
                            Tracks origin and destination of transferred assets.
                            Lists transfer date, acquired values
                          </li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          APVG expertise lies in FAM and our vast knowledge in
                          this activity helps to provide ready solutions to our
                          elite clientele in all sectors. Our PAN India
                          presence, helps us to provide seamless quality service
                          across India.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Internal Controls over Financial Reporting (ICFR)
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Section 134(5)( e) of the 2013 act requires that in
                          case of listed companies, Directors’ Responsibility
                          Statement should, among other matters, state that
                          directors had laid down internal financial controls
                          and such financial controls are adequate and were
                          operating effectively Rule 8(5)(viii) of the Companies
                          Rules, 2014 Requires the board report of all companies
                          to state the details in respect of adequacy of
                          internal financial controls with reference to the
                          financial statements also Section 143(3)(i) of the Act
                          requires the auditors’ report to state whether the
                          company has adequate internal financial controls
                          system in place and the operating effectiveness of
                          such controls.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          ICFR therefore is a process designed to provide
                          reasonable assurance regarding the reliability of
                          financial reporting and the preparation of financial
                          statements for external purposes in accordance with
                          generally accepted accounting principles. A company’s
                          internal financial control over financial reporting
                          includes those policies and procedures that pertain to
                          the maintenance of records that, in reasonable detail,
                          accurately and fairly reflect the transactions and
                          dispositions of the assets of the company.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Provide reasonable assurance that transactions are
                          recorded as necessary to permit preparation of
                          financial statements in accordance with generally
                          accepted accounting principles, and that receipts and
                          expenditures of the company are being made only in
                          accordance with authorizations of management and
                          directors of the company provides reasonable assurance
                          regarding prevention or timely detection of
                          unauthorized acquisition, use, or disposition of the
                          company’s assets that could have a material effect on
                          the financial statements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container_son_first_dropdown2">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Fixed Asset Management (FAM)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                    >
                      <div class="accordion-body">
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            FAM is an accounting process to keep a track on
                            companies Fixed Asset for the purpose of financial
                            accounting, asset maintenance and theft prevention
                          </li>
                          <li>
                            FAM aims to track the location, quantity, condition,
                            maintenance and depreciation status of their fixed
                            assets
                          </li>
                          <li>FAM consists of two activities namely:</li>
                          <ol>
                            <li>Fixed Assets Verification (FAV)</li>
                            <li> Fixed Assets Register (FAR) </li>
                          </ol>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Income Computation and Disclosure Standards (ICDS)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Income Computation and Disclosure Standards (ICDS),
                          introduced with effect from 1st April, 2015,
                          constitute one of the significant amendments
                          introduced in the Indian Income-tax legislation. ICDS
                          are applicable for computation of income taxable as
                          ‘Business income’ or ‘Other’ Sources income. These
                          standards provide for some significant departure from
                          Indian Accounting Standards and may lead to material
                          tax implications for taxpayers. In this regard, we
                          provide assistance in examining ICDS having regard to
                          nature of business & transactions of the taxpayers and
                          advising on relevant tax implications.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Virtual Chief Financing Officer (CFO)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Virtual CFO is a seasoned finance professional who
                          helps you in running your finance function with his
                          expert advice and hands on involvement. As a virtual
                          CFO, we engage a dedicated resource who shall perform
                          the following activities on regular basis –
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Monitor the compliance of pre-agreed budgets and
                            operating business plan of the Company;
                          </li>
                          <li>
                            Reviewing the costs/expenses above the pre-decided
                            threshold level before the payment is made by the
                            Company.
                          </li>
                          <li>
                            Supervising the accounts team and Reviewing and
                            presentation of MIS to the equity investors on a
                            periodical basis.
                          </li>
                          <li>
                            Assisting in budgeting and forecasting including
                            sensitivity analysis under different market
                            scenarios.
                          </li>
                          <li>
                            Assisting on implementing financial processes and
                            controls in conjunction with the client team.
                          </li>
                          <li>
                            Reviewing the compliance of direct and indirect tax,
                            companies act matters, ROC filings, etc. in
                            conjunction with the tax consultants and company
                            secretary of the Company.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default India;
