import React from "react";
import { OurContent1, OurContent2 } from "../Data";
import "../Pages/Main/Main.css";
const Our_cultures = () => {
  return (
    <>
      <div className="our_content grid">
        {OurContent1.map(({ icon, title, description }, index) => {
          return (
            <div className="our-items-container grid" key={index}>
              <span className="our-itmes our-icon">{icon}</span>
              <span className="our-itmes our-title">{title}</span>
              <span className="our-itmes our-para">{description}</span>
            </div>
          );
        })}
      </div>

      <div className="our_content grid">
        {OurContent2.map(({ icon, title, description }, index) => {
          return (
            <div className="our-items-container grid" key={index}>
              <span className="our-itmes our-icon">{icon}</span>
              <span className="our-itmes our-title">{title}</span>
              <span className="our-itmes our-para">{description}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Our_cultures;
