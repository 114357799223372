import React, { useState } from "react";
import { ServiceDrop } from "../Data";
import { NavLink } from "react-router-dom";
import "./Dropdown.css";

const Dropdown = () => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <ul
        className={
          dropdown
            ? "services-submenu clicked"
            : "services-submenu submenu-active"
        }
        onClick={() => setDropdown(!dropdown)}
      >
        {ServiceDrop.map(({ name, path }, index) => {
          return (
            <li
              className="dropdown_item"
              key={index}
            >
              <NavLink
                to={path}
                className={({ isActive }) =>
                  isActive ? "dropdown_link active-nav" : "dropdown_link"
                }
                onClick={() => setDropdown(false)}
              >
                <h3 className="dropdown_name">{name}</h3>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Dropdown;
