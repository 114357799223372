import {
  FaRegLightbulb,
  FaBuilding,
  FaHandshake,
  FaUsers,
  FaComments,
  FaBook,
  FaThList,
  FaCoins,
  FaVihara,
  FaUserTie,
  FaChalkboardTeacher,
  FaClone,
  FaFileInvoice,
  FaSistrix,
  FaBookOpen,
  FaPuzzlePiece,
  FaProjectDiagram,
  FaDraftingCompass,
  FaDropbox,
  FaChartLine,
  FaUser,
  FaAngleRight,
} from "react-icons/fa";
import { BsDisplay } from "react-icons/bs";
import { BiCoinStack } from "react-icons/bi";
import lottie from "lottie-web";
import { defineElement } from "lord-icon-element";

export const Links = [
  {
    id: 1,
    name: "About Us",
    path: "/",
  },

  {
    id: 2,
    name: "Services",
    icon:<FaAngleRight className="dropdown-icon" />
  },

  {
    id: 3,
    name: "Industries",
    path: "/Industries",
  },

  {
    id: 4,
    name: "Team",
    path: "/Team",
  },

  {
    id: 5,
    name: "Contact Us",
    path: "/Contact",
  },

  {
    id: 6,
    name: "Are You A Startup ?",
    path: "/AreYou",
  },
];

export const ServiceDrop = [
  {
    id: 1,
    name: "Audit and Assurance",
    path: "/Audit",
    cName: "submenu-item",
  },
  {
    id: 2,
    name: "Business Advisory and Internal Audit",
    path: "/Business",
    cName: "submenu-item",
  },
  {
    id: 3,
    name: "Book Keeping and Outsourcing ",
    path: "/Book",
    cName: "submenu-item",
  },
  {
    id: 4,
    name: "Domestic Taxation and International Taxation ",
    path: "/Domestic",
    cName: "submenu-item",
  },
  {
    id: 5,
    name: "Goods and Service Tax ",
    path: "/Goods",
    cName: "submenu-item",
  },
  {
    id: 6,
    name: "Inbound and Outbound Investment and FEMA Advisory services ",
    path: "/Inbound",
    cName: "submenu-item",
  },
  {
    id: 7,
    name: "India Specific Services ",
    path: "/India",
    cName: "submenu-item",
  },
  {
    id: 8,
    name: "Company Secretarial Services ",
    path: "/Company",
    cName: "submenu-item",
  },
  {
    id: 9,
    name: "Other Services",
    path: "/Other",
    cName: "submenu-item",
  },
];

export const OurContent1 = [
  {
    id: 1,
    icon: <FaSistrix />,
    title: "TRANSPRANCY",
    description:
      "Transparency, first within self and then with the world will lead to openness, fair-mindedness, and receptiveness. At APVG- open sharing of information and thoughts, and building a participatory community are promoted on all levels of management.",
  },
  {
    id: 2,
    icon: <FaRegLightbulb />,
    title: "CLARITY",
    description:
      "Unity of One’s Thoughts x Words x Actions lend immense clarity of purpose and is essential for the effective functioning of the organization. Therefore, peers are encouraged to be forthcoming and concise while communicating. Hence, the policy to “listen first, react later to understand others better” is of utmost importance at APVG.",
  },
  {
    id: 3,
    icon: <FaHandshake />,
    title: "AUTONOMY",
    description:
      "We strongly believe that Freedom and Productivity go hand in hand. The management is there to guide and provide feedback to the employees while also giving them enough liberty to choose their approach to tackle situations.",
  },
];

export const OurContent2 = [
  {
    id: 1,
    icon: <FaUsers />,
    title: "COMRADESHIP",
    description:
      "Love and respect are the key ingredients to make a community of humble and respectful colleagues who are willing to assist and appreciate each other and address conflicts compassionately.",
  },
  {
    id: 2,
    icon: <FaBuilding />,
    title: "ENTREPRENEURSHIP",
    description:
      "At APVGwe do not promote the culture of being a Cog in the Corporate machinery but focus on everyone's holistic human and professional development. We aim to create visionaries who take strategic risks and work passionately to provide unparalleled client services.",
  },
  {
    id: 3,
    icon: <FaComments />,
    title: "EMPATHY",
    description:
      "Deep understanding of clients’ problems leads to effective and wise decision-making and strategic thinking while avoiding ambiguity. We work successfully by re-conceptualizing issues and discovering practical solutions to complex problems.",
  },
];

export const ServicesLinks = [
  {
    id: 1,
    Name: "Audit and Assurance",
    icon: <FaFileInvoice />,
    path: "/Audit",
  },

  {
    id: 2,
    Name: "Business Advisory & Internal Audit",
    icon: <FaBook />,
    path: "/Business",
  },

  {
    id: 3,
    Name: "Book Keeping and Outsourcing",
    icon: <FaThList />,
    path: "/Book",
  },

  {
    id: 4,
    Name: "Domestic Taxation & International Taxation",
    icon: <BsDisplay />,
    icon2: <BiCoinStack />,
    path: "/Domestic",
  },
  {
    id: 5,
    Name: "Goods & Service Tax",
    icon: <FaCoins />,
    path: "/Goods",
  },
  {
    id: 6,
    Name: "Inbound & Outbound Investment and FEMA Advisory services",
    icon: <FaChalkboardTeacher />,
    path: "/Inbound",
  },
  {
    id: 7,
    Name: "India Specific Services",
    icon: <FaVihara />,
    path: "/India",
  },
  {
    id: 8,
    Name: "Company Secretarial Services",
    icon: <FaUserTie />,
    path: "/Company",
  },
  {
    id: 9,
    Name: "Other Services",
    icon: <FaClone />,
    path: "/Other",
  },
];

export const ServeIons1 = [
  {
    id: 1,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/cjieiyzp.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Banking",
  },
  {
    id: 2,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/xxduqotz.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Infrastructure & Construction",
  },
  {
    id: 3,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/gqdnbnwt.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Financial Services",
  },
  {
    id: 4,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/jxbsnyig.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Power",
  },
  {
    id: 5,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/raayvuis.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Oil & Gas",
  },
  {
    id: 6,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/uukerzzv.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Trading",
  },
  // {
  //   id: 7,
  //   icon: (
  //     <lord-icon
  //       src="https://cdn.lordicon.com/hbjlznlo.json"
  //       trigger="loop"
  //       delay="500"
  //       colors="primary:#121331,secondary:#08a88a"
  //       style={{ width: "60px", height: "60px" }}
  //     ></lord-icon>
  //   ),
  //   name: "Pharmaceuticals",
  // },
  {
    id: 7,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/hdiorcun.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Healthcare",
  },
];
export const ServeIons2 = [
  {
    id: 1,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rvrbsoyg.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Textiles",
  },
  {
    id: 2,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/qhgmphtg.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "E-Commerce",
  },
  // {
  //   id: 3,
  //   icon: (
  //     <lord-icon
  //       src="https://cdn.lordicon.com/slkvcfos.json"
  //       trigger="loop"
  //       delay="500"
  //       colors="primary:#121331,secondary:#08a88a"
  //       style={{ width: "60px", height: "60px" }}
  //     ></lord-icon>
  //   ),
  //   name: "Retail",
  // },
  {
    id: 3,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/xzbfkhjf.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Manufacture",
  },
  {
    id: 4,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/iahpzgqp.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Media & Entertainment",
  },
  // {
  //   id: 6,
  //   icon: (
  //     <lord-icon
  //       src="https://cdn.lordicon.com/soseozvi.json"
  //       trigger="loop"
  //       delay="500"
  //       colors="primary:#121331,secondary:#08a88a"
  //       style={{ width: "60px", height: "60px" }}
  //     ></lord-icon>
  //   ),
  //   name: "Professional Services",
  // },
  {
    id: 5,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/wxnxiano.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Education",
  },
];
export const ServeIons3 = [
  {
    id: 1,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/yyecauzv.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Government",
  },
  {
    id: 2,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/rgyftmhc.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Insurance",
  },
  {
    id: 3,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/fqrjldna.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "IT & ITES",
  },
  {
    id: 4,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/gqzfzudq.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Aviation",
  },
  {
    id: 5,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/uetqnvvg.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Transportation & Logistics",
  },
  // {
  //   id: 6,
  //   icon: (
  //     <lord-icon
  //       src="https://cdn.lordicon.com/jjjmlddk.json"
  //       trigger="loop"
  //       delay="500"
  //       colors="primary:#121331,secondary:#08a88a"
  //       style={{ width: "60px", height: "60px" }}
  //     ></lord-icon>
  //   ),
  //   name: "Development",
  // },
  {
    id: 6,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/szzsfswk.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Hospitality",
  },
  {
    id: 7,
    icon: (
      <lord-icon
        src="https://cdn.lordicon.com/ckuogwdx.json"
        trigger="loop"
        delay="500"
        colors="primary:#121331,secondary:#08a88a"
        style={{ width: "60px", height: "60px" }}
      ></lord-icon>
    ),
    name: "Automative",
  },
];

export const areyou = [
  {
    id: 1,
    icon: <FaBookOpen />,
    name: "A unique platform for information / knowledge sharing",
  },
  {
    id: 2,
    icon: <FaPuzzlePiece />,
    name: "End-to-end tailored solutions",
  },
  {
    id: 3,
    icon: <FaCoins />,
    name: "Financial services specific to start-up",
  },
  {
    id: 4,
    icon: <FaUsers />,
    name: "Advisory, compliance and consultancy services",
  },
  {
    id: 5,
    icon: <FaUserTie />,
    name: "Virtual CFO",
  },
  {
    id: 6,
    icon: <FaProjectDiagram />,
    name: "Process mapping",
  },
  {
    id: 7,
    icon: <FaDraftingCompass />,
    name: "Business process re-engineering",
  },
  {
    id: 8,
    icon: <FaDropbox />,
    name: "Planning and Modelling",
  },
  {
    id: 9,
    icon: <FaUser />,
    name: "Mentoring",
  },
  {
    id: 10,
    icon: <FaChartLine />,
    name: "Collaborative growth environment for the start ups",
  },
];

defineElement(lottie.loadAnimation);
