import React from "react";
import Footer from "../../Components/notation";

const Goods = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="Good_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Goods and Service Tax
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_first_child">
              <div className="first_child_sonfirst_audit"></div>
            </div>
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  What is GST?
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Destination based consumption tax, i.e., tax on inter-state
                    supply to accrue to destination state
                  </li>
                  <li>
                    Dual GST model – CGST to be central tax and SGST to be state
                    tax
                  </li>
                  <li>
                    IGST on inter-state transaction, to be shared between centre
                    and states
                  </li>
                  <li>Single taxable event, i.e., supply</li>
                  <li>Four tier tax rate and creditable compensation cess</li>
                  <li>
                    Matching concept introduced for admissibility of credit
                  </li>
                </ul>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  How can we help?
                </h3>
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Impact Analysis
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Mapping current business model vis-a-vis model under GST
                    regime
                  </li>
                  <li>Analysing key tax impacts on transactions undertaken</li>
                  <li>Evaluation of agreements</li>
                  <li>Examining eligibility of tax credits into GST regime</li>
                  <li>Simulating I/O cost structure</li>
                  <li>Identifying issues and developing mitigation plan</li>
                </ul>
              </div>
            </div>
            <div className="container_first_audit_content_fifth_child grid grid_fifty_percen_content">
            </div>
            <div className="container_first_audit_content_six_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Implementation Planning
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>Assistance in aligning tax reports, invoices</li>
                  <li>Providing insights on factors for vendor negotiation</li>
                  <li>Advice on transitional issues</li>
                  <li>Preparing GST-compliance calendar</li>
                  <li>Conducting training sessions</li>
                  <li>Assisting IT-team to make GST compliant IT-system</li>
                  <li>
                    Verification of tax calculation in system after IT
                    implementation
                  </li>
                  <li>Assisting in aligning the existing RCMs</li>
                  <li>
                    Helping to identify internal checks for reducing errors
                  </li>
                  <li>
                    Revalidating impact analysis on the basis of final GST law
                  </li>
                </ul>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  GST: Boon or Bane?
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>Increased rate of tax on services</li>
                  <li>Credit of state tax available to service sector</li>
                  <li>Credit of services available to traders</li>
                  <li>Extensive compliance procedures</li>
                  <li>Reduced multiplicity of taxes</li>
                  <li>Anti-profiteering measure</li>
                  <li>Warehousing to be business call instead of tax call</li>
                  <li>
                    Identification of inter-state or intra-state supply for each
                    transaction
                  </li>
                  <li>FOC transactions with related party to be taxable</li>
                  <li>
                    Extensive technology refresh warranted to develop
                    GST-compliant system
                  </li>
                </ul>
              </div>
            </div>
            <div className="container_first_audit_content_seven_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Post Implementation Support
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Checking eligibility and effective utilization of credit
                  </li>
                  <li>Periodical review of monthly I/O data & returns</li>
                  <li>On call routine advisory</li>
                  <li>Suggesting changes to bring efficiency</li>
                  <li>Updating the organization with relevant legal changes</li>
                  <li>Support with ASP/GSP</li>
                </ul>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  GST Audit
                </h3>
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  GST Representation Services
                </h3>
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  GST Training/ Workshops
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Tailormade trainings and workshops for employees and vendors
                  </li>
                  <li>
                    Sector specific training sessions from industry experts
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Goods;
