import React from "react";
import Footer from "../../Components/notation";

const Inbound = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="Inbound_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Inbound and Outbound <br /> Investment and FEMA <br /> Advisory
                services
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Consulting Services
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Advisory on Inbound Structuring i.e. structuring of
                    Investments into India & advising on the nature & structure
                    of the Business entity (e.g., Liaison Office, Branch Office,
                    Joint Venture, Wholly Owned Subsidiary etc) .
                  </li>
                  <li>
                    Assistance in setting up of, or purchase of an existing
                    entity .
                  </li>
                  <li>
                    Assistance in obtaining Approvals & Compliance with various
                    requirements of RBI/ FEMA .
                  </li>
                  <li>
                    Approval from the Foreign Investment Promotions Board
                    (FIPB), Ministry of Finance and assistance in preparation of
                    the Foreign Collaboration Agreements .
                  </li>
                  <li>Advisory on change/ exit from existing Business model .</li>
                  <li>
                    Advisory on Outbound Structuring, i.e. choice of appropriate
                    jurisdiction, structuring of investments etc .
                  </li>
                  <li>
                    Assistance in regular FEMA compliances as per statutory
                    provisions like filing of Form/Returns etc .
                  </li>
                </ul>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Compounding Services
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>Assistance in Compounding of Offences under FEMA .</li>
                  <li>Assistance in the process of voluntarily admitting the contravention, pleading guilty and seeking redressal under FEMA,1999</li>
                  <li>Preparation of Application with RBI .</li>
                  <li>Submission of applications seeking compounding of contraventions under section 3(a) of FEMA to the Directorate of Enforcement at RBI .</li>
                  <li>Representation before RBI .</li>
                  
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Inbound;
