
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import React from "react";
import Navbar from "./Components/Navbar";
import Main from "./Pages/Main/Main";
import Industries from "./Pages/Industries/Industries";
import Team from "./Pages/Team/Team";
import Contact from "./Pages/Contact/Contact";
import AreYou from "./Pages/AreYou/AreYou";
import Audit from "./Pages/Services/Audit";
import Book from "./Pages/Services/Book";
import Business from "./Pages/Services/Business ";
import Company from "./Pages/Services/Company";
import Domestic from "./Pages/Services/Domestic";
import Goods from "./Pages/Services/Goods";
import Inbound from "./Pages/Services/Inbound";
import India from "./Pages/Services/India";
import Other from "./Pages/Services/Other";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route index element={<Main />} />
        <Route path="Audit" element={<Audit />} />
        <Route path="Book" element={<Book />} />
        <Route path="Business" element={<Business />} />
        <Route path="Company" element={<Company />} />
        <Route path="Domestic" element={<Domestic />} />
        <Route path="Goods" element={<Goods />} />
        <Route path="Inbound" element={<Inbound />} />
        <Route path="India" element={<India />} />
        <Route path="Other" element={<Other />} />
        <Route path="Industries" element={<Industries />} />
        <Route path="Team" element={<Team />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="AreYou" element={<AreYou />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
