import React from "react";
import "./Team.css";
import Footer from "../../Components/notation";

const Team = () => {
  window.addEventListener("scroll", floating_contents);
  function floating_contents() {
    var floating_content = document.querySelectorAll(".floating_contents");
    for (var i = 0; i < floating_content.length; i++) {
      var windowheight = window.innerHeight;
      var floating_contenttop = floating_content[i].getBoundingClientRect().top;
      var floating_contentpoint = 150;

      if (floating_contenttop < windowheight - floating_contentpoint) {
        floating_content[i].classList.add("active");
      } else {
        floating_content[i].classList.remove("active");
      }
    }
  }
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_team linear_grdient_section">
          <div className="contact_img mix_blend_img"></div>
          <div className="team_section_first_container1 padding_container_text_container1">
            <div className="contents-headings">
              <h1 className="team_content_heading title services_parts_heading">
                Coming Together is Beginning, Working Together is Success!
              </h1>
            </div>
          </div>
        </section>
        <section className="section_team_second">
          <h3 class="team_sub_heading sub_heading_parts">
            Our Managing Partners
          </h3>
        </section>
        <section className="section_team_third">
          <div className="team_grid_boxes grid">
            <div className="team_box flex">
              <div className="Industries_floating_content flex">
                <div className="flexbox_container_floating_contents_team flex">
                  <div className="floating_content-team floating_contents">
                    <div className="img_floating_content_team team2"></div>
                    <div className="heading_floating_container team">
                      <h3 className="heading_floating_content">
                        CA Ashish Jain
                      </h3>
                    </div>
                  </div>
                  <div className="floating_content-team floating_contents">
                    <div className="img_floating_content_team team3"></div>
                    <div className="heading_floating_container team">
                      <h3 className="heading_floating_content">
                        CA Prakhar Aggarwal
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Team;
