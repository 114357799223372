import React from "react";
import Footer from "../../Components/notation";

const Domestic = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="audit_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Domestic Taxation and <br />
                International Taxation
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first_dropdown1">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Domestic Taxation
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  We are the one stop shop for all Direct and Indirect Taxation
                  related matters whether it is compliances or representation
                  services related matters namely:
                </p>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Corporate Taxation
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Corporate Tax Return</li>
                          <li>Tax Audit</li>
                          <li>Withholding Tax – Retainership & Advisory</li>
                          <li>Tax Consultancy and Advisory</li>
                          <li>
                            Opinions on various transactions from domestic law
                            to tax treaty viewpoint .
                          </li>
                          <li>Advising on tax planning opportunities</li>
                          <li>Taxation relating Certifications – 15CB, etc.</li>
                          <li>
                            Conducting due diligence reviews and Health Check
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Litigation
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Representation Services for Assessments before
                            Income-tax officers
                          </li>
                          <li>
                            Appeal Proceedings before CIT(A) as well as Income
                            Tax Appellate Tribunal
                          </li>
                          <li>Withholding Tax – Retainership & Advisory</li>
                          <li>
                            Assistance in appeal proceedings before High Courts
                            and Supreme Courts (Preparation of case, Briefing
                            Counsels)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        High Net Worth Individual
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Review of books of Accounts</li>
                          <li>Tax Audit</li>
                          <li>
                            Preparation & Computation of Income & Taxes thereon
                          </li>
                          <li>Preparation & Submission of ITR</li>
                          <li>Advisory on taxation matters</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Income Computation and Disclosure Standards (ICDS)
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          {" "}
                          Transfer Pricing law was introduced in India in 2001.
                          It deals with curbing tax avoidance by laying down
                          norms for computation of income arising from
                          international transactions or specified domestic
                          transactions (“SDTs”) having regard to the “arm’s
                          length price”. Our Transfer Pricing services offer
                          effective solutions to companies which undertake
                          international transactions or specified domestic
                          transactions with its associated enterprise or group
                          companies which are as follows:{" "}
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Transfer Pricing
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        {" "}
                        <p className="para services_parts_sub_para sub_para_contents">
                          {" "}
                          Transfer Pricing law was introduced in India in 2001.
                          It deals with curbing tax avoidance by laying down
                          norms for computation of income arising from
                          international transactions or specified domestic
                          transactions (“SDTs”) having regard to the “arm’s
                          length price”. Our Transfer Pricing services offer
                          effective solutions to companies which undertake
                          international transactions or specified domestic
                          transactions with its associated enterprise or group
                          companies which are as follows:{" "}
                        </p>{" "}
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          {" "}
                          <li>Transfer Pricing Analysis</li>{" "}
                          <li>Selection of the appropriate method</li>{" "}
                          <li>Benchmarking Analysis</li>{" "}
                          <li>
                            {" "}
                            Assistance in preparation of Transfer Pricing
                            documentation{" "}
                          </li>{" "}
                          <li>Issuance of Transfer Pricing Certificate</li>{" "}
                          <li>
                            {" "}
                            Representing Transfer Pricing Cases before Transfer
                            Pricing Officer/ Appellate Authorities{" "}
                          </li>{" "}
                        </ul>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container_son_first_dropdown2">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  International Taxation
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  At APVG, we provide the following services under International
                  Taxation:
                </p>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Transfer Pricing
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Transfer Pricing law was introduced in India in 2001.
                          It deals with curbing tax avoidance by laying down
                          norms for computation of income arising from
                          international transactions or specified domestic
                          transactions (“SDTs”) having regard to the “arm’s
                          length price”. Our Transfer Pricing services offer
                          effective solutions to companies which undertake
                          international transactions or specified domestic
                          transactions with its associated enterprise or group
                          companies which are as follows:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Transfer Pricing Analysis</li>
                          <li>Selection of the appropriate method</li>
                          <li>Benchmarking Analysis</li>
                          <li>
                            Assistance in preparation of Transfer Pricing
                            documentation
                          </li>
                          <li>Issuance of Transfer Pricing Certificate</li>
                          <li>
                            Representing Transfer Pricing Cases before Transfer
                            Pricing Officer/ Appellate Authorities
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Expat Taxation
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Taxing for expatriate individuals can be a problem. It
                          needs know-how’s and experience to ensure smooth
                          transactions. The problem might begin with submitting
                          the form but it does not end there. Tax clearance, tax
                          reviews, offshore banking and investment, all this and
                          more are involved. Tax compliance, however, is a must,
                          but one also has to plan how to optimize tax liability
                          or burden by restructuring the transactions and assets
                          correctly. We with our experts take care of every
                          detail with great focus on the following:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Entry meeting with Expatriates</li>
                          <li>FRRO registration</li>
                          <li>Review of Agreement</li>
                          <li>
                            Preparation & Computation of Income & Taxes thereon
                          </li>
                          <li>Preparation & Submission of Income Tax Return</li>
                          <li>Advisory on Taxation Matters</li>
                          <li>
                            Obtaining Income-tax Clearance from tax authorities
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Double Tax Avoidance Advisory
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          The Double Tax Avoidance Agreements (DTAA) are
                          essentially bilateral agreements entered into between
                          two countries, in our case, between India and another
                          foreign state. We facilitate our client in DTAA
                          Advisory to avoid double taxation based on the nature
                          of Income and expenditure, in both the countries (i.e.
                          Double taxation of same income) and withholding tax
                          issues and other compliance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Domestic;
