import React from "react";
import { ServicesLinks } from "../Data";
import "../Pages/Main/Main.css";
import { Link } from "react-router-dom";
const Services_links = () => {
  return (
    <>
      {ServicesLinks.map(({ Name, icon, path }, index) => {
        return (
          <li className="Our_services_link flex" key={index}>
            <Link className="heading_link" to={path}>
              {Name}
            </Link>
            <span className="icon_our_services">{icon}</span>
          </li>
        );
      })}
    </>
  );
};

export default Services_links;
