import React from "react";
import "./Main.css";
import SERVICES_LINKS from "../../Components/Services_Links";
import OUR_CULTURE from "../../Components/Our_Cultures";
import WE_SERVE from "../../Components/We_Serve";
import Footer from "../../Components/notation";

const Main = () => {
  return (
    <>
      <main id="Main_container">
        {/* first section start here */}
        <section className="Main_container_first_section">
          <div className="teamimage"></div>
          <div className="Main_container_content_first_section flex">
            <h1 className="title first_title">
              PROVIDING A COMPREHENSIVE RANGE OF FINANCIAL,AUDIT AND CONSULTANCY
              SERVICES
            </h1>
          </div>
        </section>
        {/* second section start here */}
        <section className="Main_container_second_section">
          <h1 className="subtitle our_subtitle">OUR JOURNEY TO EMINENCE</h1>
          <div className="Main_container_second_section_container_1 grid">
            <div className="Main_container_second_section_container_2">
              <div className="para para_second">
                <p className="para">
                  M/s APVG & Co. was established in 2019 and registered with
                  Institute of Chartered Accountants of India (Firm Registration
                  no. FNR 035902N) with it's headquarter situated at New Delhi
                  (the capital of India). The firm has been set up by a group of
                  young, enthusiastic, highly skilled and motivated
                  professionals who have taken experience from top consulting
                  firms and are extensively experienced in their chosen fields
                  has providing a wide array of Accounting, Auditing, Taxation,
                  Assurance and Business advisory services to various clients
                  and their stakeholders. Focus at providing tailor made
                  solutions to challenging problems of our clients, and perform
                  with high quality and timely service Over the years the
                  organization has grown at a rapid pace in its size as well as
                  capabilities. With a large number of assignments handled till
                  now, APVG& Co. has developed expertise in the areas of
                  Financial, Auditing & Consultancy Services.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* third section start here */}
        <section className="Main_container_third_section">
          <div className="lines_bg ourculuture_container1">
            <span className="lines"></span>
            <span className="lines go3"></span>
            <span className="lines go2"></span>
            <span className="lines "></span>
            <span className="lines go4"></span>
            <span className="lines "></span>
            <span className="lines go4"></span>
            <span className="lines go5"></span>
            <span className="lines"></span>
            <span className="lines go1"></span>
            <span className="lines"></span>
            <span className="lines go4"></span>
            <span className="lines go1"></span>
            <span className="lines go1"></span>
            <span className="lines go5"></span>
            <span className="lines go4"></span>
            <span className="lines"></span>
            <span className="lines go1"></span>
            <span className="lines"></span>
            <span className="lines go1"></span>
            <span className="lines go2"></span>
            <span className="lines go5"></span>
            <span className="lines go3"></span>
            <span className="lines go4"></span>
            <span className="lines "></span>
            <span className="lines go4"></span>
            <span className="lines go5"></span>
            <span className="lines"></span>
            <span className="lines go1"></span>
            <span className="lines"></span>
            <span className="lines go4"></span>
            <span className="lines go5"></span>
            <span className="lines go1"></span>
            <span className="lines go2"></span>
            <span className="lines go5"></span>
            <span className="lines go2"></span>
            <span className="lines "></span>
            <span className="lines go4"></span>
            <span className="lines "></span>
          </div>
          <h1 className="subtitle culture_subtitle">OUR CULTURE & VALUES</h1>
          <div className="Main_container_third_section_container2 our_culture_and_values grid">
            <div className="our_culture_and_values_undercontainer first_container grid">
              <OUR_CULTURE />
            </div>
          </div>
        </section>
        {/* fourth section start here */}
        <section className="Main_container_fourth_section">
          <h1 className="subtitle Services_subtitle">OUR SERVICES</h1>
          <ul className="our_services_list grid">
            <SERVICES_LINKS />
          </ul>
        </section>
        {/* fifth section start here */}
        <section className="Main_container_fifth_section">
          <h1 className="subtitle Services_subtitle">INDUSTRIES WE SERVE</h1>
          <p className="para para_industries">
            With an in-depth understanding of each industry, we are able to
            deliver a more contextual approach to each engagement.
          </p>
          <div className="Industries_icons_conatiner">
            <div className="serve_container grid">
              <WE_SERVE />
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Main;
