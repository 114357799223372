import React from "react";
import "./content.css";
import Footer from "../../Components/notation";

const Audit = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="audit_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Audit and Assurance
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Passion for Excellence
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  Statutory Audits are conducted to report the state of
                  company’s finances and accounts to the Government of India. At
                  APVG an elite team of qualified auditors are designated to do
                  such audits. The audit report are prepared strictly in
                  accordance with the rules and regulations defined by the
                  Government agencies.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Due Diligence
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  The financial due diligence process is one of the most crucial
                  part of any transaction. APVG holds an expertise and an
                  established track record in this area. Our aim is to enable
                  entrepreneurs and decision makers to make sound investment
                  decisions with great confidence at every stage. We aim at
                  eliminating the risk and potential flaws of investing at
                  domestic and international loca
                </p>
              </div>
            </div>
            <div className="container_first_audit_content_fifth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Investigation/ Forensic Audit
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  An examination and evaluation of a firm’s or an individual’s
                  financial information for the use as an evidence in the court
                  of law. At APVG we undertake financial investigations of
                  misconduct, bribery and corruption; with the main focus on
                  assessing the financial transactions, accounting
                  irregularities, and regulatory and compliance issues on behalf
                  of the public and closely held companies, private equity
                  firms, government agencies, municipalities, NGOs and HNIs/
                  industries we serve. Our well trained and qualified subject
                  matter experts handle such issues with great proficiency.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Stock Audit
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  Stock Audit is an area of specialization and core competence
                  for APVG We are committed to provide unmatched Internal Audit
                  Services because of our unparallel reach and all India
                  network. Stocks and physical assets such as raw materials are
                  critical real assets and needs systematized management. As a
                  large number of companies are operating across the borders
                  through multiple locations and channel partners, making asset
                  management a challenge. We aim at delivering focused services
                  to companies to keep their physical assets check intact.
                </p>
              </div>
            </div>
            <div className="container_first_audit_content_six_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  IND-AS/ IFRS
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  IND-AS stands for Indian Accounting Standard and are converged
                  standards for IFRS (International Financial Reporting
                  Standards) are to ensure that India converge globally accepted
                  standards and were adopted by the country and was made
                  mandatory for selected companies.
                </p>
                <p className="gapingp_para para sub_para_contents">
                  IND-AS at present can be viewed as the most important
                  initiative in the financial reporting standards at the global
                  podium. Approximately 120 nations and reporting jurisdictions
                  permits/ requires IND-AS for domestic listed companies, while
                  over 90 countries have fully conformed to IND-AS as
                  promulgated by the International Accounting Standards Board
                  (IASB) and include a statement acknowledging such conformity
                  in their audit reports. We have qualified and trained
                  resources to assist in IND-AS convergence, balancing both, the
                  short term and the long term objectives of the businesses.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  US GAAP (PCAOB)
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  The common set of generally accepted accounting principles,
                  standards and procedures that companies use to compile their
                  financial statements. GAAP is a combination of authoritative
                  standards (set by the policy board) and simply the commonly
                  accepted ways of recording/ reporting accounting information.
                  At APVG, we have accumulated the expertise needed to conduct
                  US GAAP Audits.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Audit;
