import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Swal from "sweetalert2";
import Footer from "../../Components/notation";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaGlobe,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kloyla1",
        "template_gy1bfp8",
        form.current,
        "Ec5EAa00GDuBNNQ1h"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const showalert = () => {
    var name = document.getElementById("name");
    var email = document.getElementById("email");
    var msg = document.getElementById("msg");

    if (name.value === "" || email.value === "" || msg.value === "") {
      Swal.fire("Oops...", "Something went wrong!", "error");
    } else {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        timer: 1500,
      });
    }
  };

  return (
    <>
      <main className="services_content_main">
        <section className="section_first_contact linear_grdient_section">
          <div className="contact_img mix_blend_img"></div>
          <div className="contact_section_first_container1 padding_container_text_container1">
            <div className="contents-headings">
              <h1 className="contact_content_heading title services_parts_heading">
                Contact Us
              </h1>
            </div>
          </div>
        </section>
        <section className="section_contact_second">
          <h3 className="Contact_sub_heading sub_heading_parts">Our offices</h3>
          <p className="para ready_contact_para">
            Ready to help you grow your business
          </p>
        </section>
        <section className="section_contact_third">
          <div className="section_third_container1">
            <div className="map_container">
              <div className="form">
                <div className="row">
                  <div className="col-md-7">
                    <h3>Get In Touch</h3>
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control name"
                          id="name"
                          placeholder="Enter Your Name"
                          name="user_name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          className="form-control email"
                          id="email"
                          placeholder="Enter Your Mail"
                          name="user_email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          Contact No
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Your Contact No"
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Message Me
                        </label>
                        <textarea
                          className="form-control msg"
                          id="msg"
                          rows="3"
                          placeholder="Message Me"
                          name="message"
                          required
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="contact_button submit"
                        id="send"
                        onClick={showalert}
                      >
                        <span className="link_button">Submit</span>
                      </button>
                    </form>
                  </div>
                  <div className="col-md-5">
                    <h3 className="text-white">Contact Us</h3>
                    <div className="mt-5">
                      <div className="d-flex">
                        <FaMapMarkerAlt className="icon_contact_us" />
                        <p className="para contact_heading_items">
                          <span>
                            <Link
                              to="https://goo.gl/maps/LK5N2Pne3WvrzFQZ7"
                              target="_blank_"
                            >
                              Unit No.601, 6th Floor, Aggarwal Cyber Plaza- 1,
                              Netaji Subhash Place, Pitampura , Delhi – 110034
                            </Link>
                          </span>
                        </p>
                      </div>

                      <div className="d-flex">
                        <FaPhoneAlt className="icon_contact_us" />
                        <p className="para  contact_heading_items">
                          <span> 9711926119</span>
                        </p>
                      </div>
                      <div className="d-flex">
                        <FaEnvelope className="icon_contact_us" />
                        <p className="para contact_heading_items">
                          <span>
                            {" "}
                            <Link
                              to="https://mail.google.com/mail/u/0/"
                              target="_blank_"
                            >
                              Admin@apvg.co.in
                            </Link>
                          </span>
                        </p>
                      </div>
                      <div className="d-flex">
                        <FaGlobe className="icon_contact_us" />
                        <p className="para contact_heading_items">
                          <span>
                            <Link to="http://apvg.co.in/" target="_blank_">
                              www.apvg.co.in
                            </Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Contact;
