import React from "react";
import Footer from "../../Components/notation";

const Business = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="business_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Business Advisory <br></br>and Internal Audit
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Internal Audits
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  The end result of an internal audit is no longer limited to
                  focusing on the processes and controls surrounding financial
                  reporting. Internal audit now has a more strategic role. It
                  can create value by identifying enterprise-wide risks,
                  leveraging synergies, monitoring and providing early warning
                  of new risks; and improving processes/ efficiency. APVG
                  provides a platform to its clients to achieve more from the
                  business by providing internal audit services of highest
                  standard.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Risk Assessments
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  Risk Assessment is the identification and analysis of risks
                  that influence the accomplishment of an organization’s
                  objectives, and determining the risk management processes.
                  Risk assessment involves determining the operating objectives,
                  systematic identification of activities/ events that could
                  prevent a business unit from achieving its objectives. At APVG,
                  we aim at creating an analytical tool that maps out the
                  potential alarming glitches and the preventive measures to
                  avoid those glitches.
                </p>
              </div>
            </div>
            <div className="container_first_audit_content_fifth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  SOPs Development
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  Standard Operating Procedures (SOPs) is a mandatory component
                  of any business model. SOPs act as the process book that
                  describes a set of procedures and protocols for a particular
                  action. Standard Operating Procedures describe how processes
                  execute as well as detailing the roles and resources that are
                  involved in a particular process. At APVG, we take care of SOP
                  development by creating it, reviewing it and periodically
                  updating the SOPs.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Operational Audits
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  The APVG advanced and analytical audit approach focuses on
                  internal control and operational process analysis to ensure
                  maximum productivity and efficiency in the processes/
                  practices. We work to identify the areas of weakness that
                  violate or have the potential to violate legislative business
                  policies, regulations and legal requirements. The end result
                  gives our client streamlined operations which are safe and
                  generates high ROIs.
                </p>
              </div>
            </div>
            <div className="container_first_audit_content_six_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  IS Audits
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  IS audit is an examination of the management controls within
                  an Information technology (IT) infrastructure. At APVG, we
                  conduct IS Audits in two phases. First by gathering
                  information and planning; second by gaining an understanding
                  of the existing internal control structure. A number of
                  organizations are now moving to a risk-based audit approach
                  which is used to assess risk and helps an IT/IS auditor to
                  make the decision as to whether to perform compliance testing
                  or substantive testing.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  SOX Compliance
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  Our experience and expertise enable us to provide solid
                  guidance from the start of the SOX compliance engagement by
                  creating long term compliance objectives for our clients. At
                  APVG, we aim at providing our clients with customized, industry
                  approved approaches that paves the way for our client’s
                  compliance initiatives. We aim at offering cost effective loan
                  staffing, complete SOX teams, project managers/ supervisors
                  and advisory for most industries.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Business;
