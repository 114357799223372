import React from "react";
import Footer from "../../Components/notation";

const Company = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="company_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
              Company Secretarial <br />Services 
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Advice and Assistance
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>Company/LLP/OPC formations</li>
                  <li>Name changes</li>
                  <li>Company dissolutions and strike offs</li>
                </ul>

                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Fully Outsourced Services
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>
                    Management of Annual General Meetings / Board / Committee
                    Meetings
                  </li>
                  <li>
                    Preparation, attending and minute taking for board or
                    committee meetings
                  </li>
                  <li>
                    Preparation of Agenda papers, Notices and drafting of
                    Minutes of the meetings
                  </li>
                  <li>
                    Routine and non-routine company secretarial services for
                    private, public and other types of companies
                  </li>
                  <li>Maintenance of statutory registers</li>
                  <li>Filing of annual returns and accounts</li>
                  <li>
                    Preparation and filing of forms relating to any changes in
                    your company such as change of director’s, registered
                    address etc
                  </li>
                </ul>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Other Services
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>Change in registered Office</li>
                  <li>Secretarial Health Check ups</li>
                  <li>Extension of Annual General Meetings</li>
                  <li>XBRL Filings of Financial Statement</li>
                  <li>Secretarial Due Diligence</li>
                  <li>
                    Preparation of Share Certificates, transfers, share splits
                    etc.
                  </li>
                  <li>
                    Increase in Authorised Share Capital; Change of name; Change
                    in object clause;
                  </li>
                  <li>Registration of charges, search and status report</li>
                  <li>
                    Issue of ESOP’s, Rights Issue, Private Placement as per
                    Companies Act 2013
                  </li>
                  <li>
                    Alteration in Articles of Association by incorporating
                    clauses as mentioned in joint venture agreement/shareholder
                    agreement
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Company;
