import React from "react";
import "./Industries.css";
import Footer from "../../Components/notation";

const Industries = () => {
  window.addEventListener("scroll", floating_contents);
  function floating_contents() {
    var floating_content = document.querySelectorAll(".floating_contents");
    for (var i = 0; i < floating_content.length; i++) {
      var windowheight = window.innerHeight;
      var floating_contenttop = floating_content[i].getBoundingClientRect().top;
      var floating_contentpoint = 150;

      if (floating_contenttop < windowheight - floating_contentpoint) {
        floating_content[i].classList.add("active");
      } else {
        floating_content[i].classList.remove("active");
      }
    }
  }
  return (
    <>
      <main className="services_content_main">
        <section className="section_Industries_first linear_grdient_section">
          <div className="Industries_img mix_blend_img"></div>
          <div className="section_industries_first_container_first padding_container_text_container1">
            <div className="contents-headings">
              <h1 className="indutries_content_heading title services_parts_heading">
                Industries
              </h1>
            </div>
          </div>
        </section>
        <section className="section_Industries_second">
          <h3 class="Industries_sub_heading sub_heading_parts">Industries</h3>
          <div className="Industries_floating_content flex">
            <div className="flexbox_container_floating_contents flex">
              <div className="floating_content-1 floating_contents">
                <div className="img_floating_content img1"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Infrastructure</h3>
                </div>
              </div>
              <div className="floating_content-2 floating_contents">
                <div className="img_floating_content img2"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Power Energy and Steel
                  </h3>
                </div>
              </div>
              <div className="floating_content-3 floating_contents">
                <div className="img_floating_content img3"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Petroleum</h3>
                </div>
              </div>
              <div className="floating_content-4 floating_contents">
                <div className="img_floating_content img4"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Telecom</h3>
                </div>
              </div>
              <div className="floating_content-5 floating_contents">
                <div className="img_floating_content img5"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Banking and Financial Institutions
                  </h3>
                </div>
              </div>
              <div className="floating_content-6 floating_contents">
                <div className="img_floating_content img6"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Private Equity and Venture Capitalist
                  </h3>
                </div>
              </div>
              <div className="floating_content-7 floating_contents">
                <div className="img_floating_content img7"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Infrastructure</h3>
                </div>
              </div>
              <div className="floating_content-8 floating_contents">
                <div className="img_floating_content img8"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Information Technology & Services
                  </h3>
                </div>
              </div>
              <div className="floating_content-9 floating_contents">
                <div className="img_floating_content img9"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Aviation</h3>
                </div>
              </div>
              <div className="floating_content-10 floating_contents">
                <div className="img_floating_content img10"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Hospitality and Healthcare
                  </h3>
                </div>
              </div>
              <div className="floating_content-11 floating_contents">
                <div className="img_floating_content img11"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Automobiles Auto Ancillary and Manufacturing
                  </h3>
                </div>
              </div>
              <div className="floating_content-12 floating_contents">
                <div className="img_floating_content img12"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Retail, White Goods & Consumer Electronics
                  </h3>
                </div>
              </div>
              <div className="floating_content-13 floating_contents">
                <div className="img_floating_content img13"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Realty Sector</h3>
                </div>
              </div>
              <div className="floating_content-14 floating_contents">
                <div className="img_floating_content img14"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Media and Entertainment
                  </h3>
                </div>
              </div>
              <div className="floating_content-15 floating_contents">
                <div className="img_floating_content img15"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Shipping</h3>
                </div>
              </div>
              <div className="floating_content-16 floating_contents">
                <div className="img_floating_content img16"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Trading</h3>
                </div>
              </div>
              <div className="floating_content-17 floating_contents">
                <div className="img_floating_content img17"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Paper/Sugar & Liquor
                  </h3>
                </div>
              </div>
              <div className="floating_content-18 floating_contents">
                <div className="img_floating_content img18"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Pharmaceuticals</h3>
                </div>
              </div>
              <div className="floating_content-19 floating_contents">
                <div className="img_floating_content img19"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">Education</h3>
                </div>
              </div>
              <div className="floating_content-20 floating_contents">
                <div className="img_floating_content img20"></div>
                <div className="heading_floating_container">
                  <h3 className="heading_floating_content">
                    Non Governmental Organisations
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Industries;
