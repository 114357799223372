import React from "react";
import Footer from "../../Components/notation";

const Other = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="other_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Other Services
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_first_child"></div>
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first_dropdown1">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Pre- Audit
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          The preliminary phase of an audit prior to the
                          official examination of the accuracy of an
                          organization’s financial statement, predominantly used
                          to establish the scope of the audit and the areas of
                          concern. Provides a platform for:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Gathering background information, documentations and
                            records.
                          </li>
                          <li>
                            Error rectification and prevention of ‘audit-time
                            surprises.
                          </li>
                          <li>
                            Providing point-in time financial picture of the
                            organization
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        CSR Audit
                      </button>
                    </h3>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          CSR is an initiative to evaluate and take
                          accountability of the company’s effect on the
                          environment and society by addressing the well-being
                          of internal/external stakeholders.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Strategic business benefits of CSR audit are:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Assists in creating a positive brand awareness
                          </li>
                          <li>Increased employee satisfaction,</li>
                          <li>Reduced operating costs,</li>
                          <li>
                            Improved community relations and corporate
                            accountability.
                          </li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Legislation:
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          “The Ministry of Corporate Affairs (MCA) has vide its
                          notification dated 27 February 2014, and in exercise
                          of powers conferred by section 1(3) of the Companies
                          Act, 2013 (‘the Act’), notified 1 April 2014 as the
                          date on which the provisions of section 135 and
                          Schedule VIIof the Act shall come into force. The MCA
                          has also notified the Companies (Corporate Social
                          Responsibility Policy) Rules, 2014 (‘the Rules’) to be
                          effective from 1 April 2014.”
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Quality Audit
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Quality audit is a methodical and an autonomous
                          examination to determine whether activities of a firm
                          complies with the planned arrangements and whether
                          these arrangements are implemented effectively and are
                          suitable to achieve pre-defined objectives.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          It is an effective mechanism to verify compliance
                          regulation audits with two important goals:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Audits are intended to verify that manufacturing and
                            control systems are operating under a state of
                            control.
                          </li>
                          <li>
                            udits permit timely prevention of potential
                            problems.
                          </li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Quality audits can be used to establish a high degree
                          of confidence on the product and services delivered to
                          the end users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        Inventory Management
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Control over inventories is crucial function for all
                          organizations, as the inaccuracies in inventory
                          records can cause erroneous management decisions.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Inventory Management intends to verify the accuracy of
                          inventory records by creating mandatory checkpoints
                          and controls. The process involves assigning the
                          proper carrying value to the inventory, so it can be
                          reflected properly in the company’s financial records.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Some of the activities which APVG can assist clients in
                          are:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>Store leader updation</li>
                          <li>Physical Verification & Stock taking</li>
                          <li>
                            Surprise Checks to identify the real position of
                            inventory
                          </li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          One of the basic tools for this purpose is a physical
                          count of the inventory on hand, which can be compared
                          with the financial records to detection or validation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container_son_first_dropdown2">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        Concurrent Audit
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      class="accordion-collapse collapse show"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Scrutinizing the real time financial transactions at
                          the time it has taken place/ parallel with the other
                          transactions and checking its authenticity. Concurrent
                          audit is mainly used for:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Providing a clear ‘point-in time’ financial picture
                            of an organization
                          </li>
                          <li>Delivering an error management tool</li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          APVG, has expertise in conducting concurrent audit on
                          behalf of banks and various financial institutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Internal Controls Over Financial Reporting (ICFR)
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          ICFR is a process designed to provide reasonable
                          assurance regarding the reliability of financial
                          reporting and the preparation of financial statements
                          for external purposes in accordance with GAAP.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Policies/procedures entailing a company’s ICFR are:
                        </p>
                        <ul className="para services_parts_sub_para sub_para_contents list_book">
                          <li>
                            Maintaining company’s records with reasonable
                            details, accuracy and fairness; reflecting the
                            transactions and dispositions of the assets of the
                            company;
                          </li>
                          <li>
                            Providing reasonable assurance that transactions are
                            recorded as necessary to permit preparation of
                            financial statements in accordance with GAAP, and
                            that receipts/ expenditures of the company are prior
                            management approvals.
                          </li>
                          <li>
                            Providing reasonable assurance regarding prevention
                            or timely detection of unauthorised acquisition,
                            use, or disposition of the company’s assets that
                            could have a material effect on the financial
                            statements.
                          </li>
                        </ul>
                        <p className="para services_parts_sub_para sub_para_contents">
                          Legislation:
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          “Section 134(5)( e) of the 2013 act requires that in
                          case of listed companies, Directors’ Responsibility
                          Statement should, among other matters, state that
                          directors had laid down internal financial controls
                          and such financial controls are adequate and were
                          operating effectively Rule 8(5)(viii) of the Companies
                          Rules, 2014 Requires the board report of all companies
                          to state the details in respect of adequacy of
                          internal financial controls with reference to the
                          financial statements also Section 143(3)(i) of the Act
                          requires the auditors’ report to state whether the
                          company has adequate internal financial controls
                          system in place and the operating effectiveness of
                          such controls”
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >
                        Tailored Audit
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body">
                        <p className="para services_parts_sub_para sub_para_contents">
                          Tailored audit is ‘Customized Audit’ function designed
                          to cater organization’s need. It takes the generic
                          audit to a new level through the addition of client
                          expectations, policies and processes.
                        </p>
                        <p className="para services_parts_sub_para sub_para_contents">
                          The process involves preparing documentations covering
                          pre-defined critical business requirements.
                          Tailored-audits follow the standard auditing
                          principles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Other;
