import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Links } from "../Data";
import Dropdown from "./Dropdown";

import "./Navbar.css";

const Navbar = () => {
  const [showMenu, setshowMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <nav className="nav">
        <div className={`${showMenu ? "nav_menu show-menu" : "nav_menu"}`}>
          <div className="heading_container">
            <h3 className="apvg_heading">APVG & CO.</h3>
            <h6 className="ca_heading_for_apvg_content">
              Chartered Accountants
            </h6>
          </div>
          <ul className="nav_list">
            {Links.map(({ name, path}, index) => {
              if (name === "Services") {
                return (
                  <li
                    className="nav_item"
                    key={index}
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}
                  >
                    <NavLink className="nav_link">
                      <h3 className="nav_name">{name}</h3>
                    </NavLink>
                    {dropdown && <Dropdown />}
                  </li>
                );
              } else {
                return (
                  <li className="nav_item" key={index}>
                    <NavLink
                      to={path}
                      className={({ isActive }) =>
                        isActive ? "nav_link active-nav" : "nav_link"
                      }
                      onClick={() => setshowMenu(!showMenu)}
                    >
                      <h3 className="nav_name">{name}</h3>
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>

        <div
          className={`${showMenu ? "nav_toggle animate-toggle" : "nav_toggle"}`}
          onClick={() => setshowMenu(!showMenu)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
