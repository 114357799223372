import React from "react";
import Footer from "../../Components/notation";

const Book = () => {
  return (
    <>
      <main className="services_content_main">
        <section className="section_first_services_content_audit linear_grdient_section">
          <div className="book_img mix_blend_img"></div>
          <div className="section_first_services_content_container padding_container_text_container1">
            <div className="contents-headings">
              <h3 className="heading_services flex service_short_heading">
                SERVICES
              </h3>
              <h1 className="services_content_heading title services_parts_heading">
                Book Keeping <br></br>and Outsourcing
              </h1>
            </div>
          </div>
        </section>
        <section className="services_parts_content_container">
          <div className="services_parts_content_gridbox_sec grid">
            <div className="container_first_audit_content_third_child"></div>
            <div className="container_first_audit_content_fourth_child grid grid_fifty_percen_content">
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Book Keeping and Outsourcing
                </h3>
                <p className="para services_parts_sub_para sub_para_contents">
                  At APVG our bookkeeping services aims at cost reduction and
                  cost management for our clients. A specialized team of
                  accounting professionals have been designated to cater
                  customer’s requirements and at APVG our bookkeeping services
                  aims at cost reduction and cost management for our clients. A
                  specialized team of accounting professionals have been
                  designated to cater customer’s requirements and processes.
                </p>
              </div>
              <div className="container_son_first">
                <h3 className="services_parts_sub_heading sub_heading_parts">
                  Some of the bookkeeping services that we offer are:-
                </h3>
                <ul className="para services_parts_sub_para sub_para_contents list_book">
                  <li>AR/AP services</li>
                  <li>Bank account reconciliation</li>
                  <li>Invoicing Processing Services</li>
                  <li>Manual Journal Entry Services</li>
                  <li>General ledger maintenance</li>
                  <li>Expenses ledger maintenance</li>
                  <li>Preparing ageing reports & summaries</li>
                  <li>Preparing financial statements</li>
                  <li>Trial balance services</li>
                  <li>Preparing income statements (Profit & Loss)</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <Footer />
        </section>
      </main>
    </>
  );
};

export default Book;
